/* eslint-disable */

$(document).ready(function() {

    // at page load wire up modal links to use an iframe fancybox
    wireUpFancyBoxModals($(document));

    // Setup fancy input click events
    $(document).on('keydown', '.focusableCheckboxIconInput, .focusableRadioIconInput, .focusableRotatableIcon', function (event) {
        var keycode = (event.keyCode ? event.keyCode : event.which);

        // enter or space triggers click listeners on input
        if (keycode === 13 || keycode === 32) {
            event.stopPropagation();
            event.preventDefault();

            var $iconInput = $(this);

            if ($iconInput.hasClass('focusableRadioIconInput')) {
                $iconInput.siblings('input[type=radio]').trigger('click');
            } else {
                $iconInput.trigger('click');
            }

            $iconInput.focus();
        }
    });
});

function wireUpFancyBoxModals($container) {
    // wire up modal links to use an iframe lightbox:
    $('a[rel|=modal]', $container).each(function () {
        var $target = $(this);
        $target.fancybox(GetFancyboxArgumentObject($target));

        // Hack amb: Without overloading the pos function the user would be able to scroll
        // using the mouse wheel to other modals available on the page (CRVTWO-7009)
        $.fancybox.pos = function () { }
    });
}

function GetFancyboxArgumentObject($target) {
    if ($target === undefined) {
        $target = null;
    }

    // Override the defaults if attributes are available
    var width = $target == null ? 350 : $target.attr("data-modal-width");
    var height = $target == null ? 100 : $target.attr("data-modal-height");
    var type = $target == null ? 'iframe' : $target.attr("data-modal-type");
    var borderless = $target == null
        ? false
        : ($target.attr("data-modal-borderless") || '').toLowerCase() === 'true';

    var noOuterPadding = $target == null
        ? false
        : ($target.attr("data-modal-noOuterPadding") || '').toLowerCase() === 'true';
    var showCloseButton = $target == null
        ? false
        : ($target.attr("data-modal-showCloseButton") || "").toLowerCase() === "true";
    
    width = (!width || isNaN(width) ? 350 : parseInt(width));
    height = (!height || isNaN(height) ? 100 : parseInt(height));
    type = !type ? 'iframe' : type;

    var fancyboxArgumentObject = {
        'type': type,
        'scrolling': 'no',
        'margin': 0,
        'padding': 0,
        'width': width,
        'height': height,
        'speedIn': 0,
        'autoSize': true,
        'showNavArrows': false,
        'hideOnOverlayClick': false, // disable clicking outside the dialog to close fancybox
        'enableEscapeButton': false, // disable hitting the Esc key to close fancybox
        'onStart': function(links, index) {

            if ($('#fancybox-outer .mp-fancybox-loading').length >= 0) {
                $('.mp-fancybox-loading').remove();
            }

            // Add a fancy box loading symbol to the modal
            $('#fancybox-outer').append('<div class="mp-fancybox-loading">&nbsp;</div>');

            /* for the updated borderless workflow, temporarily hide fancybox content */
            /* in the case of loading mulitiple modals in a row, this will prevent protruding smaller divs */
            if (this.borderless || borderless) {
                $('#fancybox-wrap').addClass('fancybox__borderless-rounded');
                $('#fancybox-content').addClass('invisible');
            } else {
                $('#fancybox-wrap').removeClass('fancybox__borderless-rounded');
            }
            
        this.hiddenOverlay
                ? $('#fancybox-overlay, #fancybox-wrap').addClass('fancybox__overlay-hidden')
                : $('#fancybox-overlay, #fancybox-wrap').removeClass('fancybox__overlay-hidden');

            this.noCenter
                ? $('#fancybox-wrap').addClass('fancybox__centering--none')
                : $('#fancybox-wrap').removeClass('fancybox__centering--none');

            this.noOuterPadding || noOuterPadding
                ? $('#fancybox-wrap').addClass('fancybox__outer-padding--none')
                : $('#fancybox-wrap').removeClass('fancybox__outer-padding--none');

            this.positionFixedSW
                ? $('#fancybox-wrap').addClass('fancybox__position-fixed--south-west')
                : $('#fancybox-wrap').removeClass('fancybox__position-fixed--south-west');
            
            this.showCloseButton = $target == null || $target.attr("data-modal-showCloseButton") === undefined ? this.showCloseButton : showCloseButton;

            // return false to cancel empty links (e.g. disabled links):
            return !$(links[index]).hasClass('disabled') && (links[index].toString().length > 0);
        },
        'onComplete': function () {
            $.fancybox.center(0);

            // This will be present for setting `this.borderless = true`
            $('#fancybox-content').removeClass('invisible');

            if ($('#fancybox-frame').length) {
                //automatically set the height of the iframe lightbox:
                $('#fancybox-frame').on("load", function () {
                    $('.mp-fancybox-loading').remove();

                    var $tar = $(this);
                    setTimeout(function () {
                        $('#fancybox-content').height($tar.contents().find('body').height());
                        $.fancybox.center(0);
                    },
                        30);
                });
            } else {
                // if this isn't in an iframe the modal will load once the ajax results are returned,
                // so we won't need to wait to remove the loading spinner
                $('.mp-fancybox-loading').remove();
            }
        }
    };

    var isNoClose = $target != null ? $target.attr('data-modal-no-close') : false;
    if (isNoClose && isNoClose.toLowerCase() == 'true') {
        fancyboxArgumentObject.hideOnOverlayClick = false;
        fancyboxArgumentObject.enableEscapeButton = false;
        fancyboxArgumentObject.showCloseButton = false;
    } else {
        fancyboxArgumentObject.hideOnOverlayClick = true;
        fancyboxArgumentObject.enableEscapeButton = true;
        fancyboxArgumentObject.showCloseButton = true;
    }
    return fancyboxArgumentObject;
}

/* eslint-enable */

export {
    wireUpFancyBoxModals,
    GetFancyboxArgumentObject
}