//Track all product views (cover image click, title link click, etc)
var initProductClickTrackingEvents = function () {
    $($webtrackContainer.productClickElements).on('click', function () {
        // get the id of the product from the element's data attribute
        var itemId = $(this).attr('data-wtitemid');
        var listId = $(this).attr('data-wtlistid');
        $webtrackContainer.trackProductClick(itemId, false, listId);
    });
}

var WebtrackContainer = function() {
    var $container = this;
    var $wtTitles = [];

    var $productClickElements = '';
    var $promoView = {};

    $container.productClickElements = $productClickElements;

    $container.trackingEnabled = true;

    $container.assignProductClickElements = function(elements) {
        // don't assign any handlers if no elements passed into assignment
        if (typeof elements === "undefined" || elements.length == 0) {
            return;
        }
        $container.productClickElements = elements;
        initProductClickTrackingEvents();
    }

    // represents a single title
    var webtrackTitle = function(titleObj) {
        var $title = this;
        $title.ReserveId = titleObj.ReserveId;
        $title.CrossRefId = titleObj.CrossRefId;
        $title.DistributionModelId = titleObj.DistributionModelId;
        $title.Quantity = titleObj.Quantity;
        $title.Position = titleObj.Position;
        $title.CartId = titleObj.CartId;
        // When a search has been executed, preface search terms with the word "Search:" so the 'list' property can easily be distinguished b/t search results and featured list
        if (titleObj.SearchTerms != null) {
            $title.SearchTerms = 'Search: ' + titleObj.SearchTerms;
        } else {
            $title.SearchTerms = 'Search: ';
        }
        $title.ListName = titleObj.ListName;
        $title.ListId = titleObj.ListId;
    }

    $container.webtrackTitles = $wtTitles;

    // track initial user login
    $container.trackLogin = function() {
        initDataLayer(trackingEvents.LOGIN);
    }

    // initialize the data layer on page load
    $container.initPageLoad = function() {
        initDataLayer();
    }

    // convert one or more titles to js object & add to array
    // allowDupes: whether or not to allow a title with the same index (title id) to be added to the title array
    $container.addTitles = function(titles, allowDupes) {
        if (typeof allowDupes === "undefined" || allowDupes === null) {
            allowDupes = false;
        }
        $(titles).each(function() {
            var wtTitle = new webtrackTitle(this);
            addTitle(wtTitle, allowDupes);
        });

    }
    // update quantity value for a given title
    $container.updateTitleQuantity = function(titleId, quantity) {
        var updateTitle = findWebtrackTitle(titleId);
        if(updateTitle){
            updateTitle.Quantity = quantity;
        }
    }

    // set the quantity value of each title to a designated value
    $container.updateAllTitleQuantities = function(quantity) {
        for (var key in $wtTitles) {
            $wtTitles[key].value.Quantity = quantity;
        }
    }

    // track when all title quantities are being updated to a specific value
    $container.trackUpdateAllQuantities = function(cartId, quantity, loadSu) {
        trackUpdateAllQuantities(cartId, quantity, loadSu);
    }

    // update distribution model value for a given title
    $container.updateTitleDistributionModel = function(titleId, disModelId) {
        var updateTitle = findWebtrackTitle(titleId);
        updateTitle.DistributionModelId = disModelId;
    }

    // add a single webtrack title to the collection
    var addTitle = function(titleObj, allowDupes) {
        // only add the title if it doesn't already exist in the array,
        // or if duplicates are explicitly allowed
        if (allowDupes || !arrayContainsTitle(titleObj.CrossRefId)) {
            $wtTitles.push({
                key: titleObj.CrossRefId,
                value: titleObj
            });
        }
    }

    // returns whether or not a title (by identifier) already exists in the array
    var arrayContainsTitle = function(titleId) {
        for (var i = 0, len = $wtTitles.length; i < len; i++) {
            if ($wtTitles[i].key === titleId)
                return true;
        }
        return false;
    }

    // searches the array for a title by crossRefId
    var findWebtrackTitle = function(titleId) {
        var title = $.grep($wtTitles, function(e) { return e.key == titleId; });

        return title[0] && title[0].value
            ? title[0].value
            : null;
    }

    var findWebtrackTitleByListId = function(titleId, listId) {
        var title = $.grep($wtTitles,
            function(e) {
                return (e.key == titleId && e.value.ListId == listId);
            });

        return title[0] && title[0].value
            ? title[0].value
            : null;
    }

    // track a single title (product view)
    $container.trackTitleDetail = function(titleId) {
        trackTitleDetails(titleId, $promoView);
    }


    // track a product click
    $container.trackProductClick = function(titleId, trackById, listId) {
        if (!(typeof trackById === "undefined") && (trackById === true)) {
            trackTitleById(titleId, trackingEvents.PRODUCTCLICK);
        } else {
            var title;
            if (!(typeof listId === "undefined")) {
                title = findWebtrackTitleByListId(titleId, listId);
            } else {
                title = findWebtrackTitle(titleId);
            }

            if (title !== null) {
                trackProductClick(title);
            }
        }
    }

    // track a collection of title impressions
    $container.trackTitleImpressions = function() {
        var titles = [];
        // dump the titles into an array that can be passed into the controller
        for (var key in $wtTitles) {
            titles.push($wtTitles[key].value);
        }
        trackImpressions(titles, $promoView);
    }

    // track titles that become visible when a carousel prev/next arrow is clicked
    $container.trackCarouselClick = function(titleIdList) {
        var titleArray = [];
        var idArray = titleIdList.split(',');
        // retrieve all titles being viewed/made visible in the carousel and add them to the array to be sent into the tracking call
        for (var i = 0; i < idArray.length; i++) {
            var title = findWebtrackTitle(idArray[i]);
            titleArray.push(title);
        }
        trackCarouselClick(titleArray);
    }

    $container.trackCreateCart = function(cart, callBack) {
        trackCreateCart(cart, callBack);
    }

    // track add to cart for any number of titles
    $container.trackAddToCart = function(titleIdList, cartId, callBack, listId) {
        // don't track if adding all search results to cart
        if (titleIdList == '*') {
            return;
        }
        var titleArray = [];
        var idArray = titleIdList.split(',');
        // retrieve all titles being added to the cart and add them to the array to be sent into the tracking call
        for (var i = 0; i < idArray.length; i++) {
            var title;
            // if featured list id is provided, use it to retrieve title
            if (!(typeof listId === "undefined")) {
                title = findWebtrackTitleByListId(idArray[i], listId);
            } else {
                title = findWebtrackTitle(idArray[i]);
            }
            if(!title){
                return;
            }
            title.CartId = cartId;
            // it's possible to add a title to a cart w/o a quantity value (empty box).  In this case, set the quantity value to 1
            if (title.Quantity.length == 0 || title.Quantity == 0) {
                title.Quantity = 1;
            }
            titleArray.push(title);
        }

        trackAddToCart(titleArray, callBack);
    }

    $container.trackRemoveFromCart = function(titleIdList) {
        var titleArray = [];
        var idArray = titleIdList.split(',');
        // retrieve all titles being added to the cart and add them to the array to be sent into the tracking call
        for (var i = 0; i < idArray.length; i++) {
            var title = findWebtrackTitle(idArray[i]);
            titleArray.push(title);
        }
        trackRemoveFromCart(titleArray);
    }

    $container.trackCheckout = function(cartId, step, loadSu) {
        trackCheckout(cartId, step, loadSu);
    }
    $container.trackCheckoutPaymentOption = function(cartName, paymentOption) {
        trackCheckoutPaymentOption(cartName, paymentOption);
    }

    $container.trackCheckoutTransaction = function(orderId) {
        trackCheckoutTransaction(orderId);
    }

    $container.trackClassSetCheckout = function(titleId, disModelId, userCount, checkoutStep) {
        trackClassSetCheckout(titleId, disModelId, userCount, checkoutStep);
    }

    $container.trackPromoView = function() {
        // don't push if nothing's in the promoView object (e.g. ad info failed to be loaded)
        if ($.isEmptyObject($promoView)) {
            return;
        }
        var response = {
            ecommerce: {}
        };
        response.ecommerce.promoView = $promoView;
        // track promoView as an impressionTrack (CRVTWO-7782)
        doDataPush(response, true, trackingEvents.IMPRESSIONS);
    }

    $container.trackPromoClick = function(adId) {
        // don't push if nothing's in the promoView object (e.g. ad info failed to be loaded)
        if ($.isEmptyObject($promoView)) {
            return;
        }
        var response = {
            ecommerce: {
                promoClick: {
                    promotions: []
                }
            }
        };

        var ad = $.grep($promoView.promotions,
            function(e) {
                return (e.id == adId);
            });

        response.ecommerce.promoClick.promotions.push(ad[0]);
        doDataPush(response, true, trackingEvents.PROMOCLICK);
    };

    $container.trackGoogleClassroomClick = function(title) {
        trackGoogleClassroomClick(title);
    };

    $container.trackGeneralPref = function(objForm) {
        trackGeneralPreferencesUpdate(objForm);
    };

    $container.trackSearchPresets = function (params) {
        trackSearchPresetsUpdate(params);
    };

    $container.trackEmailPreferences = function(objForm) {
        trackEmailPreferencesUpdate(objForm);
    };

    $container.trackShelfTalker = function (objForm, titleIds) {
        trackShelfTalkerUpdate(objForm, titleIds);
    };
};

// tracking events 'enum'
var trackingEvents = {
    "DETAIL": "detail",
    "ADDTOCART": "addToCart",
    "REMOVEFROMCART": "removeFromCart",
    "CHECKOUT": "checkout",
    "CHECKOUTOPTION": "checkoutOption",
    "CHECKOUTTRANSACTION": "transaction",
    "PRODUCTCLICK": "productClick",
    "CAROUSELCLICK": "carouselClick",
    "LOGIN": "login",
    "IMPRESSIONS": "impressionTrack",
    "PROMOCLICK": "promotionClick",
    "CREATECART": "createCart",
    "GOOGLECLASSROOM": "googleClassClick",
    // preferences update:
    "SEARCHPRESETS": "searchPresets",
    "GENERALPREF": "generalPref",
    "EMAILPREF": "emailPref",
    "SHELFTALKER": "shelfTalker",
    "BULKSHELFTALKER": "bulkShelfTalker"
};

// initialize datalayer upon page load
var initDataLayer = function(event) {
    $.ajax({
        url: '/Webtrack/Init',
        async: false,
        success: function(response) {
            if (response.Success === true) {
                doDataPush(response, false, event);
            }
        }
    });
};

// assemble the components of the datalayer and send to web tracking
var doDataPush = function(response, includeEcommerce, trackingEvent) {
    if (notTrackable()) {
        return;
    }

    var dataLayer = window.dataLayer = window.dataLayer || [];
    var dataPush = {};
    // user and content should only be populated once per page load -- do not add to push if they are null
    if (!(response.user === null) && !(typeof (response.user) === "undefined")) {
        dataPush['user'] = response.user;
    }
    if (!(response.content === null) && !(typeof (response.content) === "undefined")) {
        dataPush['content'] = response.content;
    }
    // TODO nbeaudoin: per CRVTWO-7318 we aren't retrieving the 'totCarts' value currently, so do not add session object to push.  Possibly to be added at a future date
    //dataPush['session'] = response.session;
    if (includeEcommerce) {
        dataPush['ecommerce'] = response.ecommerce;
    }
    if (!(typeof trackingEvent === "undefined")) {
        dataPush['event'] = trackingEvent;
    }

    dataLayer.push(dataPush);
};

var trackTitleDetails = function(itemId, promoView) {
    $.ajax({
        url: '/Webtrack/TrackTitleDetails?itemId=' + itemId,
        async:
            false, // (CRVTWO-7746) title details (productView) tracking must be done synchronously, so that the push occurs before the global GTM container is pushed
        dataType: 'json',
        success: function(response) {
            if (response.Success === true) {
                //CRVTWO-9503 if any promo view data exists, add it to the title details ecommerce
                if (!($.isEmptyObject(promoView))) {
                    response.ecommerce.promoView = promoView;
                }
                //CRVTWO-9503 track title details as impressions event
                doDataPush(response, true, trackingEvents.IMPRESSIONS);
            }
        }
    });
};

var trackImpressions = function(titles, promoView) {
    titles = JSON.stringify({ 'titles': titles });

    $.ajax({
        type: "POST",
        url: '/Webtrack/TrackImpressions',
        data: titles,
        async: true,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        success: function(response) {
            if (response.Success === true) {
                // if promoView objects exist, add them to the ecommerce
                if (!($.isEmptyObject(promoView))) {
                    response.ecommerce.promoView = promoView;
                }
                doDataPush(response, true, trackingEvents.IMPRESSIONS);
            }
        }
    });
};

var trackCarouselClick = function(titles) {
    // identical to impression tracking, except that an event is passed in the data layer
    titles = JSON.stringify({ 'titles': titles });
    $.ajax({
        type: "POST",
        url: '/Webtrack/TrackImpressions',
        data: titles,
        async: true,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        success: function(response) {
            if (response.Success === true) {
                doDataPush(response, true, trackingEvents.CAROUSELCLICK);
            }
        }
    });
};

var trackProductClick = function(title) {

    title = JSON.stringify({ 'title': title });
    $.ajax({
        type: "POST",
        url: '/Webtrack/TrackProductClick',
        data: title,
        async: true,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        success: function(response) {
            if (response.Success === true) {
                doDataPush(response, true, trackingEvents.PRODUCTCLICK);
            }
        }
    });
};

// tracks a title by id, rather than passing a title object
var trackTitleById = function(titleId, trackingEvent) {
    $.ajax({
        type: "GET",
        url: '/Webtrack/TrackProductClickById?id=' + titleId,
        async: true,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        success: function(response) {
            if (response.Success === true) {
                doDataPush(response, true, trackingEvent);
            }
        }
    });
};


var trackUpdateAllQuantities = function(cartId, quantity, loadSu) {
    if (!$.isNumeric(cartId) || !$.isNumeric(quantity)) {
        return;
    }
    $.ajax({
        type: "POST",
        url: '/Webtrack/TrackCartUpdateAllQuantities?cartId=' +
            cartId +
            '&quantity=' +
            quantity +
            '&loadSimUse=' +
            loadSu,
        async: true,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        success: function(response) {
            if (response.Success === true) {
                doDataPush(response, true, trackingEvents.ADDTOCART);
            }
        }
    });
};

var trackCreateCart = function(cart, callBack) {
    cart = JSON.stringify({ 'cart': cart });

    $.ajax({
        type: "POST",
        url: "/Webtrack/TrackCreateCart",
        data: cart,
        async: true,
        contentType: "application/json; charset=urt-8",
        dataType: "json",
        success: function(response) {
            if (response.Success === true) {
                response.content = {
                    cartID: response.content.cartID.toString(),
                    cartName: response.content.cartName,
                    cartType: response.content.cartType
                };
                doDataPush(response, false, trackingEvents.CREATECART);
            }
        },
        complete: callBack
    });
};

var trackAddToCart = function(titles, callBack) {
    titles = JSON.stringify({ 'titles': titles });
    $.ajax({
        type: "POST",
        url: '/Webtrack/TrackAddToCart',
        data: titles,
        async: true,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        success: function(response) {
            if (response.Success === true) {
                doDataPush(response, true, trackingEvents.ADDTOCART);
            }
        },
        complete: callBack
    });
};

var trackRemoveFromCart = function(titles) {
    titles = JSON.stringify({ 'titles': titles });
    $.ajax({
        type: "POST",
        url: '/Webtrack/TrackRemoveFromCart',
        data: titles,
        async: true,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        success: function(response) {
            if (response.Success === true) {
                doDataPush(response, true, trackingEvents.REMOVEFROMCART);
            }
        }
    });
};

var trackCheckout = function(cartId, step, loadSu) {
    var trackUrl = '/Webtrack/TrackCheckout?cartId=' + cartId + '&step=' + step;
    // if necessary assign flag so controller/provider knows to load SU titles
    if (!(typeof loadSu === "undefined")) {
        trackUrl += '&loadSimUse=true';
    }
    $.ajax({
        type: "POST",
        // using the step value as part of the track checkout actions name allows for one central 'checkout' function
        url: trackUrl,
        async: true,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        success: function(response) {
            if (response.Success === true) {
                doDataPush(response, true, trackingEvents.CHECKOUT);
            }
        }
    });
};

var trackCheckoutPaymentOption = function(cartName, option) {
    if (notTrackable()) {
        return;
    }

    // since no server-side data is necessary for this push, just do it directly within the method
    var dataLayer = window.dataLayer = window.dataLayer || [];
    var dataPush = {};
    dataPush["event"] = trackingEvents.CHECKOUTOPTION;
    dataPush["ecommerce"] = {
        "checkout_option": {
            "actionField": {
                "step": 2,
                "option": option, // pay by cc, content credit or bill my account
                "list": cartName // name of cart being purchased
            }
        }
    };
    dataLayer.push(dataPush);
};

var trackCheckoutTransaction = function(identifier) {
    // call transaction method.
    // if success,
    // 1: push to datalayer
    // 2: call checkout step 3 method
    // 2.5: push to datalayer
    $.ajax({
        url: '/Webtrack/TrackCheckoutTransaction?identifier=' + identifier,
        async: true,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        success: function(response) {
            if (response.Success === true) {

                doDataPush(response, true, trackingEvents.CHECKOUTTRANSACTION);
                // checkout step 3 ('checkout') does not require any sort of data detail, so retrieve what's needed and do the push here
                trackCheckoutStep3(response);
            }
        }
    });
};

var trackCheckoutStep3 = function(response) {
    if (notTrackable()) {
        return;
    }

    // since no server-side data is necessary for this push, just do it directly
    var dataLayer = window.dataLayer = window.dataLayer || [];
    var dataPush = {};
    dataPush["event"] = trackingEvents.CHECKOUT;
    dataPush["ecommerce"] = {
        "checkout": {
            "actionField": {
                "step": 3, // Step 3 - confirmation
                "list": response.ecommerce.purchase.actionField.list // name of cart purchased
            }
        }
    };
    dataLayer.push(dataPush);
};

var notTrackable = function() {
    if (!$webtrackContainer.trackingEnabled) {
        return true;
    }

    return false;
};

var trackClassSetCheckout = function(itemId, disModelId, userCount, checkoutStep) {
    var trackUrl = '/Webtrack/TrackClassSetCheckout?itemId=' +
        itemId +
        '&disModelId=' +
        disModelId +
        '&userCount=' +
        userCount +
        '&checkoutStep=' +
        checkoutStep;
    $.ajax({
        type: "POST",
        url: trackUrl,
        async: true,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        success: function(response) {
            if (response.Success === true) {
                response.ecommerce.checkout.actionField.step = checkoutStep;
                doDataPush(response, true, trackingEvents.CHECKOUT);
            }
        }
    });
};

var trackGoogleClassroomClick = function(title) {
    // since no server-side data is necessary for this push, just do it directly
    var dataLayer = window.dataLayer = window.dataLayer || [];
    var dataPush = {};
    // datalayer/push defined per CRVTWO-13835
    dataPush["event"] = trackingEvents.GOOGLECLASSROOM;
    dataPush["eventCategory"] = "Engagements";
    dataPush["eventAction"] = "Google Classroom";
    dataPush["eventLabel"] = title;

    dataLayer.push(dataPush);
};

var trackGeneralPreferencesUpdate = function (objForm) {
    // since no server-side data is necessary for this push (it can be derived from the form), just do it directly
    var dataLayer = window.dataLayer = window.dataLayer || [];
    var dataPush = {};
    // datalayer/push defined per CRVTWO-14443
    dataPush["event"] = trackingEvents.GENERALPREF;
    dataPush["user"] = {
        preferences: {
            general: {
                language: objForm["PreferredLanguageCode"]
            }
        }
    };
    dataLayer.push(dataPush);
};

var trackSearchPresetsUpdate = function (params) {

    // since no server-side data is necessary for this push (it can be derived from the form), just do it directly
    var dataLayer = window.dataLayer = window.dataLayer || [];
    var dataPush = {};
    // datalayer/push defined per CRVTWO-14443
    dataPush["event"] = trackingEvents.SEARCHPRESETS;
    dataPush["user"] = {
        preferences: {
            search: {
                type: params.presetUsage,

                language: params.languages !== undefined ? params.languages.join(", ") : "all",

                format: params.formatTypes !== undefined ? params.formatTypes.join(", ") : "all",

                audience: params.audiences !== undefined ? params.audiences.join(", ") : "all",

                releaseStatus: params.includePreorders !== undefined ? (params.includePreorders === true ? "preorder" : "released") : "show all",

                sort: params.selectedSortBy,

                perpage: params.pageSize,

                hideMature: params.hideMatureContent
            }
        }
    };
    dataLayer.push(dataPush);
};

var trackEmailPreferencesUpdate = function (objForm) {
    var emailObj = {};
    /* sync form values to properties GA expects */
    /*-- Holds Manager Create Cart */
    if (objForm["NotificationPreferences[ConsortiumHoldsManagerCreateCartOrOrder]"] !== undefined) {
        emailObj.HoldsManagerCartOrder =
            objForm["NotificationPreferences[ConsortiumHoldsManagerCreateCartOrOrder]"];
    }
    if (objForm["NotificationPreferences[AdvantageHoldsManagerCreateCartOrOrder]"] !== undefined) {
        emailObj.AdvHoldsManagerCartOrder =
            objForm["NotificationPreferences[AdvantageHoldsManagerCreateCartOrOrder]"];
    }
    if (objForm["NotificationPreferences[SharedHoldsManagerCreateCartOrOrder]"] !== undefined) {
        emailObj.SharedHoldsManagerCartOrder =
            objForm["NotificationPreferences[SharedHoldsManagerCreateCartOrOrder]"];
    }
    /*-- Holds Manager Low Budget */
    if (objForm["NotificationPreferences[ConsortiumHoldsManagerLowBudget]"] !== undefined) {
        emailObj.HoldsManagerLowBudget =
            objForm["NotificationPreferences[ConsortiumHoldsManagerLowBudget]"];
    }
    if (objForm["NotificationPreferences[AdvantageHoldsManagerLowBudget]"] !== undefined) {
        emailObj.AdvHoldsManagerLowBudget =
            objForm["NotificationPreferences[AdvantageHoldsManagerLowBudget]"];
    }
    if (objForm["NotificationPreferences[SharedHoldsManagerLowBudget]"] !== undefined) {
        emailObj.SharedHoldsManagerLowBudget =
            objForm["NotificationPreferences[SharedHoldsManagerLowBudget]"];
    }
    /*-- Patron Interest Manager Create Cart */
    if (objForm["NotificationPreferences[ConsortiumPatronInterestManagerCreateCart]"] !== undefined) {
        emailObj.PatronInterestManagerCreateCart =
            objForm["NotificationPreferences[ConsortiumPatronInterestManagerCreateCart]"];
    }
    if (objForm["NotificationPreferences[AdvantagePatronInterestManagerCreateCart]"] !== undefined) {
        emailObj.AdvPatronInterestManagerCreateCart =
            objForm["NotificationPreferences[AdvantagePatronInterestManagerCreateCart]"];
    }
    if (objForm["NotificationPreferences[SharedPatronInterestManagerCreateCart]"] !== undefined) {
        emailObj.SharedPatronInterestManagerCreateCart =
            objForm["NotificationPreferences[SharedPatronInterestManagerCreateCart]"];
    }
    /*-- Metered Access Manager Create Cart */
    if (objForm["NotificationPreferences[ConsortiumMeteredAccessManagerCreateCart]"] !== undefined) {
        emailObj.MeteredAccessManagerCreateCart =
            objForm["NotificationPreferences[ConsortiumMeteredAccessManagerCreateCart]"];
    }
    if (objForm["NotificationPreferences[AdvantageMeteredAccessManagerCreateCart]"] !== undefined) {
        emailObj.AdvMeteredAccessManagerCreateCart =
            objForm["NotificationPreferences[AdvantageMeteredAccessManagerCreateCart]"];
    }
    if (objForm["NotificationPreferences[SharedMeteredAccessManagerCreateCart]"] !== undefined) {
        emailObj.SharedMeteredAccessManagerCreateCart =
            objForm["NotificationPreferences[SharedMeteredAccessManagerCreateCart]"];
    }
    /*-- RTL Manager Cart */
    if (objForm["NotificationPreferences[ConsortiumRecommendToLibraryMangerCreateCartOrOrder]"] !== undefined) {
        emailObj.RTLManagerCartOrder =
            objForm["NotificationPreferences[ConsortiumRecommendToLibraryMangerCreateCartOrOrder]"];
    }
    if (objForm["NotificationPreferences[AdvantageRecommendToLibraryMangerCreateCartOrOrder]"] !== undefined) {
        emailObj.AdvRTLManagerCartOrder =
            objForm["NotificationPreferences[AdvantageRecommendToLibraryMangerCreateCartOrOrder]"];
    }
    if (objForm["NotificationPreferences[SharedRecommendToLibraryMangerCreateCartOrOrder]"] !== undefined) {
        emailObj.SharedRTLManagerCartOrder =
            objForm["NotificationPreferences[SharedRecommendToLibraryMangerCreateCartOrOrder]"];
    }
    /*-- RTL Low Budget */
    if (objForm["NotificationPreferences[ConsortiumRecommendToLibraryMangerLowBudget]"] !== undefined) {
        emailObj.RTLManagerLowBudget =
            objForm["NotificationPreferences[ConsortiumRecommendToLibraryMangerLowBudget]"];
    }
    if (objForm["NotificationPreferences[AdvantageRecommendToLibraryMangerLowBudget]"] !== undefined) {
        emailObj.AdvRTLManagerLowBudget =
            objForm["NotificationPreferences[AdvantageRecommendToLibraryMangerLowBudget]"];
    }
    if (objForm["NotificationPreferences[SharedRecommendToLibraryMangerLowBudget]"] !== undefined) {
        emailObj.SharedRTLManagerLowBudget =
            objForm["NotificationPreferences[SharedRecommendToLibraryMangerLowBudget]"];
    }
    /*-- Processing Preorders */
    if (objForm["NotificationPreferences[ConsortiumProcessingPreorderTitles]"] !== undefined) {
        emailObj.ProcessingPreorder =
            objForm["NotificationPreferences[ConsortiumProcessingPreorderTitles]"];
    }
    if (objForm["NotificationPreferences[AdvantageProcessingPreorderTitles]"] !== undefined) {
        emailObj.AdvProcessingPreorder =
            objForm["NotificationPreferences[AdvantageProcessingPreorderTitles]"];
    }
    /*-- CPC Budget */
    if (objForm["NotificationPreferences[ConsortiumCostPerCircBudgetAlert]"] !== undefined) {
        emailObj.CostPerCircBudgetAlert =
            objForm["NotificationPreferences[ConsortiumCostPerCircBudgetAlert]"];
    }
    if (objForm["NotificationPreferences[AdvantageCostPerCircBudgetAlert]"] !== undefined) {
        emailObj.AdvCostPerCircBudgetAlert =
            objForm["NotificationPreferences[AdvantageCostPerCircBudgetAlert]"];
    }
    /*-- Sim Use Expiration */
    if (objForm["NotificationPreferences[ConsortiumSimultaneousUsePlanRenwal]"] !== undefined) {
        emailObj.SimUsePlanRenwal =
            objForm["NotificationPreferences[ConsortiumSimultaneousUsePlanRenwal]"];
    }
    if (objForm["NotificationPreferences[AdvantageSimultaneousUsePlanRenwal]"] !== undefined) {
        emailObj.AdvSimUsePlanRenwal =
            objForm["NotificationPreferences[AdvantageSimultaneousUsePlanRenwal]"];
    }
    /*-- Cancelled Preorders */
    if (objForm["NotificationPreferences[ConsortiumCanceledPreorders]"] !== undefined) {
        emailObj.CanceledPreorders =
            objForm["NotificationPreferences[ConsortiumCanceledPreorders]"];
    }
    if (objForm["NotificationPreferences[AdvantageCanceledPreorders]"] !== undefined) {
        emailObj.AdvCanceledPreorders =
            objForm["NotificationPreferences[AdvantageCanceledPreorders]"];
    }
    /*-- Titles Auto-weeded */
    if (objForm["NotificationPreferences[ConsortiumTitlesWereAutomaticallyWeeded]"] !== undefined) {
        emailObj.TitlesAutoWeeded =
            objForm["NotificationPreferences[ConsortiumTitlesWereAutomaticallyWeeded]"];
    }
    /*-- MARC Express */
    if (objForm["NotificationPreferences[ConsortiumMarcExpressOrderDelivery]"] !== undefined) {
        emailObj.MarcExpOrderDelivery =
            objForm["NotificationPreferences[ConsortiumMarcExpressOrderDelivery]"];
    }
    if (objForm["NotificationPreferences[AdvantageMarcExpressOrderDelivery]"] !== undefined) {
        emailObj.AdvMarcExpOrderDelivery =
            objForm["NotificationPreferences[AdvantageMarcExpressOrderDelivery]"];
    }
    if (objForm["NotificationPreferences[SharedMarcExpressOrderDelivery]"] !== undefined) {
        emailObj.SharedMarcExpOrderDelivery =
            objForm["NotificationPreferences[SharedMarcExpressOrderDelivery]"];
    }

    // since no server-side data is necessary for this push (it can be derived from the form), just do it directly
    var dataLayer = window.dataLayer = window.dataLayer || [];
    var dataPush = {};
    // datalayer/push defined per CRVTWO-14443
    dataPush["event"] = trackingEvents.EMAILPREF;
    dataPush["user"] = {
        preferences: {
            email: emailObj
        }
    };

    dataLayer.push(dataPush);
};

var trackShelfTalkerUpdate = function (objForm, titleIds) {
    // since no server-side data is necessary for this push (it can be derived from the form), just do it directly
    var dataLayer = window.dataLayer = window.dataLayer || [];
    var dataPush = {};

    // combine message type/shelf talker option when appropriate
    var messageType = "Unavailability explainer";
    if (objForm["MessageType"] !== "UnavailabilityExplanation") {
        messageType = "ST: " + objForm["ShelfTalkerOption"];
    }

    // datalayer/push defined per CRVTWO-14652
    if (titleIds.split(";").length > 1) {
        dataPush["event"] = trackingEvents.BULKSHELFTALKER;
    } else {
        dataPush["event"] = trackingEvents.SHELFTALKER;
    }
    dataPush["titleID"] = titleIds;
    dataPush["noteType"] = messageType;
    // HasAttributeInput is false when no attribute exists, but is an array when attribute info does exist, so check that and flatten to true/false
    dataPush["attribute"] = objForm["HasAttributeInput"] === "false" ? false : true;
    // format dates as US since they will only be referenced by OD
    if ((objForm["ScheduledStart"] || '').length > 0) {
        dataPush["scheduleStart"] =
            moment(objForm["ScheduledStart"], OD.MarketplaceDates.momentJsDateFormat).format("M/D/YYYY");
    } else {
        dataPush["scheduleStart"] = "";
    }
    if ((objForm["ScheduledEnd"] || '').length > 0) {
        dataPush["scheduleEnd"] =
            moment(objForm["ScheduledEnd"], OD.MarketplaceDates.momentJsDateFormat).format("M/D/YYYY");
    } else {
        dataPush["scheduleEnd"] = "";
    }

    dataLayer.push(dataPush);
};



export {
    initProductClickTrackingEvents,
    trackingEvents,
    initDataLayer,
    doDataPush,
    trackTitleDetails,
    trackImpressions,
    trackCarouselClick,
    trackProductClick,
    trackTitleById,
    trackUpdateAllQuantities,
    trackCreateCart,
    trackAddToCart,
    trackRemoveFromCart,
    trackCheckout,
    trackCheckoutPaymentOption,
    trackCheckoutTransaction,
    trackCheckoutStep3,
    notTrackable,
    trackClassSetCheckout,
    trackGoogleClassroomClick,
    trackGeneralPreferencesUpdate,
    trackSearchPresetsUpdate,
    trackEmailPreferencesUpdate,
    trackShelfTalkerUpdate,
    WebtrackContainer
}