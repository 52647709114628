﻿(function (OD) {
    OD.MarketplaceDates = function() {
        // defaults:
        this.browserLanguage = "en-US"; // English (US) browser language
        this.datepickerDateFormat = "m/d/yy"; // en-US datepicker
        this.momentJsDateFormat = "M/D/YYYY"; // en-US date format (moment.js)
        this.extDateFormat = "MM/DD/YYYY"; // en-US date format (extjs)
    };

    // returns today's date formatted using current browser culture
    OD.MarketplaceDates.getTodaysDate = function() {
        return moment().format(OD.MarketplaceDates.momentJsDateFormat);
    };

    // formats a date using current browser culture
    OD.MarketplaceDates.formatDateValue = function(dateVal) {
        return moment(dateVal.toString(), OD.MarketplaceDates.momentJsDateFormat)
            .format(OD.MarketplaceDates.momentJsDateFormat);
    };

    // formats a date using current browser culture
    // param format is the input date vals format, can be null
    OD.MarketplaceDates.formatDateValueForExt = function(dateVal, format) {
        var d = moment(dateVal.toString(), format || OD.MarketplaceDates.extDateFormat);
        // if date is not valid, try parsing it without the format specified, such as for 2018-04-01T00:00:00 
        if (!d._isValid) {
            d = moment(dateVal.toString());
        }
        
        return d.format(OD.MarketplaceDates.extDateFormat);
    };

    // returns whether or not a date value (startDate) is prior to another (endDate)
    OD.MarketplaceDates.isDateBefore = function(startDate, endDate) {
        return moment(startDate, OD.MarketplaceDates.momentJsDateFormat)
            .isBefore(moment(endDate, OD.MarketplaceDates.momentJsDateFormat));
    };

    // returns whether or not two date values are the same
    OD.MarketplaceDates.areDatesTheSame = function(startDate, endDate) {
        return moment(endDate, OD.MarketplaceDates.momentJsDateFormat)
            .isSame(moment(startDate, OD.MarketplaceDates.momentJsDateFormat));
    };

    // returns whether or not a start date/end date combination is within a given amount of days 
    OD.MarketplaceDates.areDatesWithinRangeOfDays = function(startDate, endDate, maxNumberOfDays) {
        var dateFloor = moment(startDate, OD.MarketplaceDates.momentJsDateFormat);
        var dateCeiling = moment(startDate, OD.MarketplaceDates.momentJsDateFormat).add(maxNumberOfDays, "days");
        var withinRange = moment(endDate, OD.MarketplaceDates.momentJsDateFormat).isBetween(dateFloor, dateCeiling);
        return withinRange;
    };

    // Returns an ISO formatted date without the time portion
    OD.MarketplaceDates.getIsoStringExt = function(dateVal) {
        var d = moment(dateVal.toString(), OD.MarketplaceDates.extDateFormat);

        // if date is not valid, try parsing it without the format specified, such as for 2018-04-01T00:00:00
        if (!d._isValid) {
            d = moment(dateVal.toString());
        }

        var interpretedDate = d.format('YYYY-MM-DD');

        // In the format of 2019-10-01
        return interpretedDate;
    };
    
})(window.OD = window.OD || {});
