﻿function fnEnableProfileEditing(lblLink,lblCtrl,ctrl,div) {
    $(ctrl).css('display', 'inline');
    $(lblCtrl).css('display', 'none');
    $(ctrl).select();
    $(div).css('display', 'inline');
    $(lblLink).attr('class', 'profileEditLinkDisabled');
} 

function fnDisableProfileEditing(lblLink, lblCtrl, ctrl, div) {
    $(ctrl).css('display', 'none');
    $(lblCtrl).css('display', 'inline');
    $(div).css('display', 'none');
    $(lblLink).attr('class', 'profileEditLink');
}

// DON'T DELETE THIS!  THIS IS REQUIRED TO ADDRESS A BUG IN FIREFOX IN WHICH CHECKBOXES RETAIN THEIR CHECKSTATE WHEN THE PAGE IS REFRESHED
// Clear checkstate of all checkboxes
function ClearChecks() {
    $(":checkbox:checked").not(jQuery('.keepCheck')).prop('checked', false);
}
// Restrict user input in a text field
// create as many regular expressions here as you need:
var digitsOnly = /[1234567890]/g;
var integerOnly = /[0-9]/g;
var alphaOnly = /[A-Za-z]/g;
var usernameOnly = /[0-9A-Za-z\._-]/g;

// initialize keypress event for quantity boxes
// HACK: this functionality only works across the board (search results & title details) if restrictInput is fired after triggering the add.  At this point I cannot determine why. NB 20130128
function initAddToCartQuantityBox() {
    // assign onkeypress event handler to all quantity boxes to restrict the user input to numbers
    jQuery('.quantityBox').on('keypress', function(event) {
        if (event.keyCode == 13) {
            // update quantity in webtrack container
            updateQuantityForWebtrack($(this));

            //Find the 'Add to cart' button associated with this quantity box & trigger its click event to add the title to the cart
            jQuery('.btn.addToCart', $(this).parents('.addToCartForm')).trigger('click');
        }
        return restrictInput(this, event, digitsOnly);
    });

    // update quantity in webtrack collection for associated item id
    jQuery('.quantityBox').on('change', function() {
        if (typeof ($webtrackContainer) !== 'undefined') {
            updateQuantityForWebtrack($(this));
        }
    });

    var updateQuantityForWebtrack = function(quantBox) {
        var itemId = $(quantBox).closest(".addToCartContainer").find("input[name='ItemIdList']").val();
        if (typeof itemId === "undefined") {
            // if this is not an add to cart quantity box (update all, etc.) do nothing
            return;
        }
        var quant = $(quantBox).val();
        $webtrackContainer.updateTitleQuantity(itemId, quant);
    }

}

// This function is used in conjunction with the localized .js files in "/Scripts/Internationalization/"
// We reference the file by the current language code to get the script level translations (Ex. DynamicTranslations.fr-ca.js)
function GetDynamicTranslation(resourceKey) {

    // get resource localezed for this file
    var translationDictionary = TranslatedResources;

    // ensure resource key is not null\empty
    if (resourceKey != null && resourceKey.length > 0)
    {
        // check for a localized translation
        if (translationDictionary.hasOwnProperty(resourceKey))
        {
            return translationDictionary[resourceKey];
        }
    }
}

// initialize keypress event for JumpToCartPage boxes
function initFilterPageJump() {
    // assign onkeypress event handler to all quantity boxes to restrict the user input to numbers
    jQuery('#JumpToCartPage').on('keypress', function (event) {
            if (event.keyCode == 13) {
                return true;
            }

        return restrictInput(this, event, integerOnly);
    });
}

function restrictInput(myfield, e, restrictionType, checkdot) {
    if (!e) var e = window.event;
    var code;
    if (e.keyCode) code = e.keyCode;
    else if (e.which) code = e.which;
    var character = String.fromCharCode(code);

    // if user pressed esc... remove focus from field...
    if (code == 27) { this.blur(); return false; }

    // ignore if the user presses other keys
    // strange because code: 39 is the down key AND ' key...
    if (!e.ctrlKey && code != 9 && code != 8 && code != 36 && code != 37 && code != 38 &&  (code != 39 || (code == 39 && character == "'")) && code != 40) {
        if (character.match(restrictionType)) {
            if (checkdot == "checkdot") {
                return !isNaN(myfield.value.toString() + character);
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
}

function RemoveNonAlphaNumeric(text) {
    ///<summary>
    ///Returns a version of the string input with all spaces removed.
    ///</summary>
    return text.replace(/[^a-zA-Z0-9]/g, '');
}

function basicSearchCheckForIncludedFormats() {
    var formatChks = jQuery('.titleformats').find(':checkbox');
    var chkCount = 0;
    jQuery(formatChks).each(function() {
        if ($(this).is(':checked')) {
            chkCount++;
        }
    });
    if (chkCount > 0)
        jQuery('#SpecifyFormats').val('true');
    else
        jQuery('#SpecifyFormats').val('false');
}


Object.keys = Object.keys || (function () {
    var hasOwnProperty = Object.prototype.hasOwnProperty,
    hasDontEnumBug = !{ toString: null}.propertyIsEnumerable("toString"),
    dontEnums = [
        'toString',
        'toLocaleString',
        'valueOf',
        'hasOwnProperty',
        'isPrototypeOf',
        'propertyIsEnumerable',
        'constructor'
    ],
    dontEnumsLength = dontEnums.length;

    return function (o) {
        if (typeof o != "object" && typeof o != "function" || o === null)
            throw new TypeError("Object.keys called on a non-object");

        var result = [];
        for (var name in o) {
            if (hasOwnProperty.call(o, name))
                result.push(name);
        }

        if (hasDontEnumBug) {
            for (var i = 0; i < dontEnumsLength; i++) {
                if (hasOwnProperty.call(o, dontEnums[i]))
                    result.push(dontEnums[i]);
            }
        }

        return result;
    };
})();

if (!Array.prototype.filter) {
    Array.prototype.filter = function (fun /*, thisp */) {
        "use strict";

        if (this == null)
            throw new TypeError();

        var t = Object(this);
        var len = t.length >>> 0;
        if (typeof fun != "function")
            throw new TypeError();

        var res = [];
        var thisp = arguments[1];
        for (var i = 0; i < len; i++) {
            if (i in t) {
                var val = t[i]; // in case fun mutates this
                if (fun.call(thisp, val, i, t))
                    res.push(val);
            }
        }

        return res;
    };
}

if (!Array.prototype.remove) {
    Array.prototype.remove = function (item) {
        return jQuery.grep(this, function (value) {
            return value != item;
        });
    };
}

///<summary>Checks that a keypress is a valid one (non-symbol, non-alphabetic).  Does NOT check for numeric input</summary>
function isValidKeyInput(event) {
        
    // Allow: backspace, delete, tab, escape and enter
    if (event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 || event.keyCode == 13
        // Allow: Ctrl+A
        || (event.keyCode == 65 && event.ctrlKey === true)
        // Allow: home, end, left, right
        || (event.keyCode >= 35 && event.keyCode <= 39)) {

        return true;
    }

    return false;
}

// Returns true if browser is any Chrome version
// Source: stackoverflow.com/questions/27083790/if-or-statement-var-true-then-write-document
function isChrome() {
    return !!window.chrome && !!window.chrome.webstore;
}

// rounds to n decimal places, trailing zeros are hidden
function round(value, decimals) {
    return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

// Used for adding an antiforgery token to ajax posts
// Cribbed from stackoverflow.com/a/4074289
function AddAntiForgeryToken(data) {
    data = data || {};

    data.__RequestVerificationToken = $('#__AjaxAntiForgeryForm input[name=__RequestVerificationToken]').val();
    return data;
};

/**
 * @param {FormData} formData
 * @returns {FormData}
 */
function AddAntiForgeryTokenToForm(formData) {
    formData = formData || new FormData();

    formData.append("__RequestVerificationToken", $('#__AjaxAntiForgeryForm input[name=__RequestVerificationToken]').val());

    return formData;
}


/*
Get a query string value for the given query string variable name, optionally seach the given queryString instead of the
pages query string.
*/
function GetQueryStringParam(name, queryString) {
    if (!queryString) {
        queryString = window.location.href;
    }
    var matchResult = new RegExp('[?|&]' + name + '=([^&#]*)', "i").exec(queryString);
    return matchResult && matchResult[1] || "";
}

function DecodeURIComponentWithSpaces(dataQueryParameter) {
    // replace instances of '+' with '%20' so the native decode method will know to use spaces
    dataQueryParameter = dataQueryParameter.replace(/[+]+/g, '%20');

    return decodeURIComponent(dataQueryParameter);
}

// Cribbed from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
function isEmail(email) {
    var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
}

// Accepting two arrays, returns a list of matching items
// Cribbed from stackoverflow.com/a/17805318
function intersect(list1, list2) {
    return $.map(list1, function (el) {
        return $.inArray(el, list2) < 0 ? null : el;
    });
}

// this function removes instances of special chars from the passed string
// removes: < > \ / 
function cleanHtmlFromTerm(term) {
    while (term.search(/<+|>+|\/+|\+/) !== -1) {
        term = term.replace(/<+|>+|\/+|\\+/, '');
    }
    term = term.trim();
    return term;
}

function isPositiveInt(num) {
    return num >>> 0 === parseFloat(num);
}

// javascript string.format equivalent
String.prototype.format =  function () {
    var s = this,
        i = arguments.length;

    while (i--) {
        s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), arguments[i]);
    }
    return s;
};

// Find the width of inner text
$.fn.textWidth = function () {
        var $this = $(this),
            text = $this.html(),
            width;

        $this.html('<span class="unobtrusive">{0}</span>'.format(text));

        width = $this.find('> span').width();

        $this.html(text);

        return width;
};

// Shrinks text that expands past the boundaries of its container width wise
// Warning: only guaranteed to work for elements where raw text is the element's child
$.fn.shrinkFitText = function (args) {
    args = args || { }; 
    args.tolerancePx = args.tolerancePx || 2;
    args.minFontSize = args.minFontSize || 9;

    if (!$(this).length) {
        return;
    }

    var $fullSelection = $(this),
        $singleElement = $($fullSelection[0]),
        containerWidth = $singleElement.width();

    // If the text already fits inside the container don't do anything
    if ($singleElement.textWidth() <= containerWidth) {
        return;
    }

    var maxFont = $singleElement.css('font-size').replace('px', ''),
        minFont = 1;

    // Binary search to an appropriate font size
    for (var resizeActionCap = 0; resizeActionCap <= 15; ++resizeActionCap) {

        var sizeDifference = containerWidth - $singleElement.textWidth();
        if (0 <= sizeDifference && sizeDifference <= args.tolerancePx) {
            break;
        }

        var midFont = minFont + (maxFont - minFont) / 2;
        $singleElement.css('font-size', midFont);

        if ($singleElement.textWidth() <= containerWidth) {
            minFont = midFont;
        } else {
            maxFont = midFont;
        }
    }

    $fullSelection.css('font-size', Math.max(args.minFontSize, $singleElement.css('font-size').replace('px', '')));
}

// j11y.io/javascript/sorting-elements-with-jquery
jQuery.fn.sortElements = (function () {

    var sort = [].sort;

    return function (comparator, getSortable) {

        getSortable = getSortable || function () { return this; };

        var placements = this.map(function () {

            var sortElement = getSortable.call(this),
                parentNode = sortElement.parentNode,

                // Since the element itself will change position, we have
                // to have some way of storing its original position in
                // the DOM. The easiest way is to have a 'flag' node:
                nextSibling = parentNode.insertBefore(
                    document.createTextNode(''),
                    sortElement.nextSibling
                );

            return function () {

                if (parentNode === this) {
                    throw new Error(
                        "You can't sort elements if any one is a descendant of another."
                    );
                }

                // Insert before flag:
                parentNode.insertBefore(this, nextSibling);
                // Remove flag:
                parentNode.removeChild(nextSibling);

            };

        });

        return sort.call(this, comparator).each(function (i) {
            placements[i].call(getSortable.call(this));
        });

    };

})();

// stackoverflow.com/a/21903119
$.urlParam = function (parameter) {
    var pageUrl = decodeURIComponent(window.location.search.substring(1));
    var urlVariables = pageUrl.split('&');

    for (var i = 0; i < urlVariables.length; i++) {
        var parameterName = urlVariables[i].split('=');

        if (parameterName[0] === parameter) {
            return parameterName[1] === undefined ? true : parameterName[1];
        }
    }
}

// https://css-tricks.com/snippets/jquery/serialize-form-to-json/
$.serializeMPForm = function(frm) {
    var o = {};
    var a = $(frm).serializeArray();
    $.each(a, function () {
        if (o[this.name]) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
}

/*ReserveId values are guids - same as in ReportHelpers.js*/
function parseReserveId(titleId) {
    if (titleId.match(/^[{]?[0-9a-fA-F]{8}[-]?([0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[}]?$/) != null) {
        return true;
    }
    return false;
}

/*CrossRefId values are positive integers - same as in ReportHelpers.js*/
function parseCrossRefId (titleId) {
    return titleId >>> 0 === parseFloat(titleId);
}

function userAgentIsFireFoxOnMac() {
    return window.navigator.userAgent.includes("Macintosh") && window.navigator.userAgent.includes("Gecko");
}

$.circleSpinner =  '<div class="spinner--container blue">'+
                '<div class="spinner--circle spinner--rotate_01"></div>' +
                '<div class="spinner--circle spinner--rotate_02"></div>' +
                '<div class="spinner--circle spinner--rotate_03"></div>' +
                '<div class="spinner--circle spinner--rotate_04"></div>' +
                '<div class="spinner--circle spinner--rotate_05"></div>' +
                '<div class="spinner--circle spinner--rotate_06"></div>' +
                '<div class="spinner--circle spinner--rotate_07"></div>' +
                '<div class="spinner--circle spinner--rotate_08"></div>' +
    '</div>'

var color = {
    getRgba: function (color, alpha = 1) {
        return 'rgba(' + color + ',' + alpha + ')';
    }
};

export {
fnEnableProfileEditing,
fnDisableProfileEditing,
ClearChecks,
digitsOnly,
integerOnly,
alphaOnly,
usernameOnly,
initAddToCartQuantityBox,
GetDynamicTranslation,
initFilterPageJump,
restrictInput,
RemoveNonAlphaNumeric,
basicSearchCheckForIncludedFormats,
isValidKeyInput,
isChrome,
round,
AddAntiForgeryToken,
GetQueryStringParam,
DecodeURIComponentWithSpaces,
isEmail,
intersect,
cleanHtmlFromTerm,
isPositiveInt,
parseReserveId,
parseCrossRefId,
userAgentIsFireFoxOnMac,
color
}