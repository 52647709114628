import "../../../od.globalize.js";
import "../../../od.dates.js";

import {
  fnEnableProfileEditing,
  fnDisableProfileEditing,
  ClearChecks,
  digitsOnly,
  integerOnly,
  alphaOnly,
  usernameOnly,
  initAddToCartQuantityBox,
  GetDynamicTranslation,
  initFilterPageJump,
  restrictInput,
  RemoveNonAlphaNumeric,
  basicSearchCheckForIncludedFormats,
  isValidKeyInput,
  isChrome,
  round,
  AddAntiForgeryToken,
  GetQueryStringParam,
  DecodeURIComponentWithSpaces,
  isEmail,
  intersect,
  cleanHtmlFromTerm,
  isPositiveInt,
  parseReserveId,
  parseCrossRefId,
  userAgentIsFireFoxOnMac,
  color,
} from "../../../common_functions.js";

import {
  initProductClickTrackingEvents,
  trackingEvents,
  initDataLayer,
  doDataPush,
  trackTitleDetails,
  trackImpressions,
  trackCarouselClick,
  trackProductClick,
  trackTitleById,
  trackUpdateAllQuantities,
  trackCreateCart,
  trackAddToCart,
  trackRemoveFromCart,
  trackCheckout,
  trackCheckoutPaymentOption,
  trackCheckoutTransaction,
  trackCheckoutStep3,
  notTrackable,
  trackClassSetCheckout,
  trackGoogleClassroomClick,
  trackGeneralPreferencesUpdate,
  trackSearchPresetsUpdate,
  trackEmailPreferencesUpdate,
  trackShelfTalkerUpdate,
  WebtrackContainer,
} from "../../../od-webtrack.js";

import {
  wireUpFancyBoxModals,
  GetFancyboxArgumentObject,
} from "../../../fancyboxExtensions.js";

//common_functions
window.fnEnableProfileEditing = fnEnableProfileEditing;
window.fnDisableProfileEditing = fnDisableProfileEditing;
window.ClearChecks = ClearChecks;
window.digitsOnly = digitsOnly;
window.integerOnly = integerOnly;
window.alphaOnly = alphaOnly;
window.usernameOnly = usernameOnly;
window.initAddToCartQuantityBox = initAddToCartQuantityBox;
window.GetDynamicTranslation = GetDynamicTranslation;
window.initFilterPageJump = initFilterPageJump;
window.restrictInput = restrictInput;
window.RemoveNonAlphaNumeric = RemoveNonAlphaNumeric;
window.basicSearchCheckForIncludedFormats = basicSearchCheckForIncludedFormats;
window.isValidKeyInput = isValidKeyInput;
window.isChrome = isChrome;
window.round = round;
window.AddAntiForgeryToken = AddAntiForgeryToken;
window.GetQueryStringParam = GetQueryStringParam;
window.DecodeURIComponentWithSpaces = DecodeURIComponentWithSpaces;
window.isEmail = isEmail;
window.intersect = intersect;
window.cleanHtmlFromTerm = cleanHtmlFromTerm;
window.isPositiveInt = isPositiveInt;
window.parseReserveId = parseReserveId;
window.parseCrossRefId = parseCrossRefId;
window.userAgentIsFireFoxOnMac = userAgentIsFireFoxOnMac;
window.color = color;

// od-webtrrack
window.initProductClickTrackingEvents = initProductClickTrackingEvents;
window.trackingEvents = trackingEvents;
window.initDataLayer = initDataLayer;
window.doDataPush = doDataPush;
window.trackTitleDetails = trackTitleDetails;
window.trackImpressions = trackImpressions;
window.trackCarouselClick = trackCarouselClick;
window.trackProductClick = trackProductClick;
window.trackTitleById = trackTitleById;
window.trackUpdateAllQuantities = trackUpdateAllQuantities;
window.trackCreateCart = trackCreateCart;
window.trackAddToCart = trackAddToCart;
window.trackRemoveFromCart = trackRemoveFromCart;
window.trackCheckout = trackCheckout;
window.trackCheckoutPaymentOption = trackCheckoutPaymentOption;
window.trackCheckoutTransaction = trackCheckoutTransaction;
window.trackCheckoutStep3 = trackCheckoutStep3;
window.notTrackable = notTrackable;
window.trackClassSetCheckout = trackClassSetCheckout;
window.trackGoogleClassroomClick = trackGoogleClassroomClick;
window.trackGeneralPreferencesUpdate = trackGeneralPreferencesUpdate;
window.trackSearchPresetsUpdate = trackSearchPresetsUpdate;
window.trackEmailPreferencesUpdate = trackEmailPreferencesUpdate;
window.trackShelfTalkerUpdate = trackShelfTalkerUpdate;
window.WebtrackContainer = WebtrackContainer;

// fancybox
window.wireUpFancyBoxModals = wireUpFancyBoxModals;
window.GetFancyboxArgumentObject = GetFancyboxArgumentObject;
