(function (OD) {
    OD.Globalize = {


        validators: {
            number: function () {
                var originalNumber = $.validator.methods.number;

                return function (value, element) {
                    var translatedDecimal = Globalize.parseFloat(value);

                    return OD.Globalize.util.validateLocalizationSeparator(value) && originalNumber.call(this, translatedDecimal, element);
                }
            },

            min: function () {
                var originalMin = $.validator.methods.min;

                return function(value, element, param) {
                    if (element?.type === "date") {
                        // Enforced by native validation
                        // MPCD-1225
                        return true;
                    }

                    var translatedDecimal = Globalize.parseFloat(value);

                    return OD.Globalize.util.validateLocalizationSeparator(value) && originalMin.call(this, translatedDecimal, element, param);
                }
            },

            max: function () {
                var originalMax = $.validator.methods.max;

                return function (value, element, param) {
                    if (element?.type === "date") {
                        // Enforced by native validation
                        // MPCD-1225
                        return true;
                    }

                    var translatedDecimal = Globalize.parseFloat(value);

                    return OD.Globalize.util.validateLocalizationSeparator(value) && originalMax.call(this, translatedDecimal, element, param);
                }
            },

            range: function () {
                var originalRange = $.validator.methods.range;

                return function (value, element, param) {
                    var translatedDecimal = Globalize.parseFloat(value);

                    return OD.Globalize.util.validateLocalizationSeparator(value) && originalRange.call(this, translatedDecimal, element, param);
                };
            },

            date: function () {
                var originalDate = $.validator.methods.date;

                return function (value, element) {
                    var date = Globalize.parseDate(value);

                    return originalDate.call(this, date, element);
                }
            }
        },

        util: {
            localThousandSeparator: function () {
                var localNumberWithThousand = Globalize.format(10000, "n2");

                var nonNumerics = $.map(localNumberWithThousand.split(''), function (character) {
                    if (!$.isNumeric(character)) {
                        return character;
                    }
                });

                return (nonNumerics.length === 0 ? "" : nonNumerics[0]);
            },

            localDecimalSeparator: function () {
                var localNumberWithDecimal = Globalize.format(0.00, "n2");

                return localNumberWithDecimal[1];
            },

            // Make sure the decimal separator given matches the browser culture's
            // If english ('en-US'): then '1.23' is valid, not '1,23'
            // If french ('fr')    : then '1,23' is valid, not '1.23'
            validateLocalizationSeparator: function (amount) {
                var givenLocalizationSeparators = $.map(amount.split(''), function (character) {
                    if (!$.isNumeric(character)) {
                        return character;
                    }
                });
                
                // Find the expected localization separator for the browser culture
                var decimalLocalizationSeparator = OD.Globalize.util.localDecimalSeparator();
                
                return givenLocalizationSeparators.length === 0
                    // The decimal amounts aren't rendered with thousands separators, so valid numbers should only have 0 or 1 non-numeric characters
                    || (givenLocalizationSeparators.length === 1 && givenLocalizationSeparators[0] === decimalLocalizationSeparator);
            },

            // For French, Globalize.culture('fr')
            // Given : "123 456,789", 2
            // Return: "123456.79"
            roundLocalizedString(decimal, digits) {
                var translatedDecimal = Globalize.parseFloat(decimal);

                return translatedDecimal !== NaN
                    ? translatedDecimal.toLocaleString(window.cultures.browserRequestCulture, { minimumFractionDigits: digits, maximumFractionDigits: digits, useGrouping: false })
                    : NaN;
            }
        },

        init: function () {
            Globalize.culture(window.cultures.browserRequestCulture);

            $.validator.methods.number = OD.Globalize.validators.number();
            $.validator.methods.min = OD.Globalize.validators.min();
            $.validator.methods.max = OD.Globalize.validators.max();
            $.validator.methods.range = OD.Globalize.validators.range();
            $.validator.methods.date = OD.Globalize.validators.date();
        }
    };

    OD.Globalize.init();

})(window.OD = window.OD || {});

